<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-20 16:11:06
-->
<template>
  <div>
    <el-form :model="paperForm" :rules="dataRule" ref="paperForm" label-width="80px">
      <el-form-item label="试卷名称" prop="name">
        <el-input v-model="paperForm.name" placeholder="请输入试卷名称" style="width:100%;"></el-input>
      </el-form-item>
      <el-form-item label="试卷封面">
        <el-upload
          name="files"
          accept=".jpeg,.png,.jpg,.bmp,.gif"
          :data={tokenFlag:false}
          :action="uploadAction"
          list-type="picture-card"
          :limit="1"
          :on-change="_uploadCoverImage"
          :on-remove="_handleRemove"
          :on-success="_handleSuccess"
          :file-list="imgList"
          :headers="uploadHeader"
          :class="{hide:imgList.length > 0}"
          :style="[{ '--uploadBgColor': uploadBgColor },
          {'--uploadBorderColor': $store.state.settings.theme }]">
          <div class="upload-box">
            <i class="iconfont icon-icon_new" :style="{ color: $store.state.settings.theme }"></i>
            <el-button type="text" style="margin-right: 0;">上传图片</el-button>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="可见范围" prop="useType">
        <el-radio-group v-model="paperForm.useType">
          <el-radio :label="2">可见范围</el-radio>
          <el-radio :label="1">全部</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="useDeptId" v-if="paperForm.useType==2">
        <el-select filterable value-key="id" clearable v-model="paperForm.useDeptId" placeholder="请选择" style="width: 60%;">
          <el-option
            v-for="item in visibleDeptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="_selectDept(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="试卷描述">
        <el-input v-model="paperForm.description" type="textarea" placeholder="请输入" style="width:100%;;" rows="1"></el-input>
      </el-form-item>
      <el-form-item label="试卷介绍">
        <richTextEditor
          :key="refreshRichTextEditorKey"
          class="editor"
          ref="richTextEditor"
          :content="paperForm.introduce"
          @changeData="_getRichTextHtml"
        />
      </el-form-item>
      <div class="footer-btn">
        <el-button @click="_previewPaper">预览</el-button>
        <el-button @click="_saveSubmitPaper('save')">保存</el-button>
        <el-button type="primary" @click="_saveSubmitPaper('saveAndPublish')" v-if="paperForm.status!=1">保存并发布</el-button>
      </div>
    </el-form>
    <!-- 预览试卷--弹框--开始 -->
    <previewExamPaper
      v-if="previewExamShow"
      :previewExamShow="previewExamShow"
      :paperId="paperId"
      :questionsGroupList="questionsGroupList"
    ></previewExamPaper>
    <!-- 预览试卷--弹框--结束 -->
  </div>
</template>
<script>
import { getManageDeptListApi } from '@/api/api'
import { getCookie } from '@/utils/util'
import { getPaperDetailApi, getPaperEditApi } from '@/api/examApi'
import richTextEditor from '@/components/richTextEditor/index.vue'
import previewExamPaper from '../modules/previewExamPaper.vue'
export default {
  components: { richTextEditor, previewExamPaper },
  props: {
    questionsGroupList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uploadBgColor: this.$store.state.settings.theme,
      paperDetailInfo: {},
      paperDetailInfoCopy: {},
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload',
      uploadHeader: {
        token: getCookie('token')
      },
      visibleDeptList: [], // 可见部门列表
      paperForm: {
        name: '', // 试卷名称
        cover: '', // 封面图
        description: '', // 试卷描述
        useType: null, // 可见范围
        useDeptId: '', // 使用部门id
        useDeptName: '', // 使用部门name
        introduce: '' // 试卷介绍
      },
      imgList: [],
      refreshRichTextEditorKey: new Date().getTime(),
      previewExamShow: false, // 预览试卷弹框
      paperId: '',
      dataRule: {
        name: [
          { required: true, message: '请输入试卷名称', trigger: ['change', 'blur'] }
        ],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  watch: { // 监听数据变化
    // drawerType: {
    //   immediate: true, // 将立即以表达式的当前值触发回调
    //   handler: function (val, oldVal) {},
    //   deep: true
    // }
  },
  mounted () {
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
  },
  created () {
    this._getManageDeptList()// 部门下拉列表
    this._getPaperDetail()// 试卷详情
    this.paperId = this.$route.query.paperId// 试卷id
  },
  methods: {
    // 获取试卷详情
    _getPaperDetail () {
      getPaperDetailApi({ id: this.$route.query.paperId }).then((res) => {
        if (res.code === 200) {
          this.paperDetailInfoCopy = JSON.parse(JSON.stringify(res.data))
          this.paperDetailInfo = JSON.parse(JSON.stringify(res.data))
          this.paperForm = { ...this.paperDetailInfo }
          this.imgList = this.paperDetailInfo.cover ? [{ url: this.paperDetailInfo.cover }] : []
          console.log(this.paperForm, 'this.paperForm')
          this.refreshRichTextEditorKey = new Date().getTime()
        }
      })
    },
    // 保存
    _saveSubmitPaper (type) {
      this.$refs.paperForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.paperForm,
            status: type === 'saveAndPublish' ? 1 : this.paperForm.status,
            questions: undefined,
            optInfos: []
          }
          // 操作记录---试卷标题
          if (this.paperDetailInfoCopy.name !== this.paperForm.name) {
            const tempOptOne = {
              optType: 5,
              optContent: `${this.$store.state.userList.userName}修改了试卷标题为【${this.paperForm.name}】`
            }
            params.optInfos.push(tempOptOne)
          }
          // 操作记录---试卷封面
          if (this.paperDetailInfoCopy.cover !== this.paperForm.cover) {
            const tempOptTwo = {
              optType: 5,
              optContent: `${this.$store.state.userList.userName}修改了试卷封面`
            }
            params.optInfos.push(tempOptTwo)
          }
          getPaperEditApi(params).then((res) => {
            if (res.code === 200) {
              console.log(res.data)
              this.$message.success('保存成功！')
            }
          })
        }
      })
      // getPaperEditApi
    },
    // 上传封面图触发
    _uploadCoverImage (file, fileList) {
      // this.paperForm.cover = fileList
    },
    // 删除图片
    _handleRemove (file) {
      this.imgList = []
      this.paperForm.cover = ''
      if (this.imgList.length === 0) {
        document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'inline-block'
      }
    },
    // 上传图片成功
    _handleSuccess (res, file, fileList) {
      this.imgList = this._getImage(fileList)
      this.paperForm.cover = this.imgList[0].url
      if (fileList.length > 0) {
        document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'none'
      }
    },
    // 获取图片信息
    _getImage (arr) {
      let resData = []
      const fileArr = JSON.parse(JSON.stringify(arr))
      fileArr.forEach(el => {
        if (el.response) {
          resData.push(el.response.data[0].url)
        } else {
          resData.push(el.url)
        }
      })
      resData = resData.map(item => ({ url: item }))
      return resData
    },
    // 获取到富文本编辑器的内容
    _getRichTextHtml (editDataHtml) {
      // 获取最新的html数据
      console.log(editDataHtml, 'hgfhgfhhj')
      this.paperForm.introduce = editDataHtml
    },
    // 部门下拉列表
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    // 选择部门
    _selectDept (item) {
      this.paperForm.useDeptId = item.id
      this.paperForm.useDeptName = item.name
    },
    // 预览试卷
    _previewPaper () {
      this.previewExamShow = true
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>
<style scoped lang="scss">
.el-form{
  width:60%;
  margin-top: 16px;
}
::v-deep .hide .el-upload--picture-card {
  display: none;
}
/deep/.el-upload--picture-card {
  width: 90px;
  height: 90px;
  line-height: 20px;
  background: var(--uploadBgColor) !important;
  border-color: var(--uploadBorderColor) !important;
  color: #0089ff;
  .upload-box {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    .iconfont {
      font-size: 17px;
      color: #0089ff;
    }
  }
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}
/deep/.el-upload-list--picture-card .el-progress {
  width: 90px !important;
}
/deep/.el-progress-circle {
  width: 90px !important;
  height: 90px !important;
}
.footer-btn{
  .el-button--small{
    margin-left: 16px!important;
    font-size: 14px;
    padding: 8px 15px;
  }
  text-align: right;
}

</style>
