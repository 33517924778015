<template>
  <div class="addTopic">
    <div class="second-level">
      <div class="return-tit">
        <div class="f14 cu-p" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
        <span class="division">|</span>
        <div class="f16 col333">{{paperName}}</div>
        <div :class="paperStatus==0?'paper-status no-publish-status':(paperStatus==1?'paper-status publish-status':'paper-status stop-status')">
          <span>{{paperStatus==0?'未发布':(paperStatus==1?'已发布':'已停用')}}</span>
        </div>
      </div>
    </div>
    <!-- 试卷内容--开始 -->
    <el-tabs v-model="activeTypeName" @tab-click="_handleActiveTypeClick" class="paper-tab">
      <el-tab-pane label="试卷内容" name="first">
        <paperContent ref="paperContentRef"/>
      </el-tab-pane>
      <el-tab-pane label="试卷设置"  name="second">
        <paperSetting :questionsGroupList="questionsGroupList"/>
      </el-tab-pane>
      <el-tab-pane label="操作记录" name="third">
        <paperOperateRecord ref="paperOperateRecordRef"/>
      </el-tab-pane>
    </el-tabs>
    <!-- 试卷内容--结束 -->
    <!-- <div class="footer-btn">
      <el-button @click="$emit('goBack')">取消</el-button>
      <el-button type="primary" @click="_saveTopicData()">保存</el-button>
    </div> -->
  </div>
</template>
<script>
import paperContent from './paperContent.vue'
import paperSetting from './paperSetting.vue'
import paperOperateRecord from './paperOperateRecord.vue'
import { topicManageManyAddApi } from '@/api/examApi'
export default {
  components: { paperContent, paperSetting, paperOperateRecord },
  data () {
    return {
      activeTypeName: 'first', // tab类型
      paperStatus: '',
      questionsGroupList: [],
      paperName: ''
    }
  },
  created () {
    this.paperStatus = this.$route.query.status
    this.paperName = this.$route.query.name
  },
  methods: {
    // 切换tab
    _handleActiveTypeClick (tab) {
      // 获取内容设置里面的题目--试卷设置中预览使用
      this.$nextTick(() => {
        this.questionsGroupList = this.$refs.paperContentRef.questionsGroupList
      })
      if (tab.name === 'third') {
        this.$refs.paperOperateRecordRef._getOperationRecord()
      }
    },
    // 保存题目
    _saveTopicData () {
      const param = this.optionList.map((item) => {
        return {
          questionBankId: item.questionBankId,
          questionType: item.questionType,
          title: item.title,
          content: item.content,
          contentUrl: item.contentUrl ? item.contentUrl.join(',') : '',
          answer: item.answer,
          score: item.score,
          options: JSON.stringify(item.options),
          analysis: item.analysis,
          analysisUrl: item.analysisUrl ? item.analysisUrl.join(',') : '',
          otherInstructions: item.otherInstructions
        }
      })
      topicManageManyAddApi({ questionInfos: param }).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.$router.go(-1)
        }
      })
    },
    _backBtn () {
      this.$router.push({
        path: '/examPaperManage'
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .second-level{
    // border-bottom: 1px solid #F3F3F3;
    .return-tit{
      margin-bottom: 0;
    }
    padding: 16px 24px 0px 24px;
  }
  .addTopic{
    background: #fff;
    .content{
      height: calc(100vh - 230px);
    }
  }
  // 试卷tab
  .paper-tab{
    padding: 16px 24px;
    min-height: 600px;
    /deep/.el-tabs__header{
      margin: 0px!important;
    }
  }
  // 试卷状态--样式
  .paper-status{
    margin-left: 16px;
    width: 60px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 400;
    border-radius: 4px;
  }
  .no-publish-status{
    background: linear-gradient(to right, #FF9250,#FF6767);
  }
  .publish-status{
    background: linear-gradient(to right, #37AAF8,#4A9CFE);
  }
  .stop-status{
    background: linear-gradient(to right, #9BABC5,#75849B);
  }
  // 底部保存
  .footer-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0;
    box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.04);
  }
</style>
