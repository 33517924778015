import { render, staticRenderFns } from "./addExamPaper.vue?vue&type=template&id=7e0289df&scoped=true&"
import script from "./addExamPaper.vue?vue&type=script&lang=js&"
export * from "./addExamPaper.vue?vue&type=script&lang=js&"
import style0 from "./addExamPaper.vue?vue&type=style&index=0&id=7e0289df&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0289df",
  null
  
)

export default component.exports